import { get } from 'widgets/toolbox/util';
import { scrollWindowTo } from 'widgets/toolbox/scroll';
import { getContentByUrl } from 'widgets/toolbox/ajax';
import {
    appendParamToURL
} from 'widgets/toolbox/util';

const keyCode = Object.freeze({
    RETURN: 13
});
const GRID_UPDATING_EVENT = 'products.grid.updated';

/**
 * @typedef {ReturnType<typeof import('widgets/product/ProductTile').default>} ProductTile
 * @typedef {ReturnType<typeof import('widgets/forms/InputSelect').default>} InputSelect
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} RefElement
 * @typedef {InstanceType<typeof import('widgets/Widget').default>} widget
 */

/**
 * @param Tabs Base widget for extending
 * @returns ProductListingMgr widget
 */
export default function (Tabs: ReturnType<typeof import('widgets/global/Tabs').default>) {
    /**
     * @category widgets
     * @subcategory search
     * @class ProductListingMgr
     * @augments Tabs
     * @classdesc Represents ProductListingMgr component with next features:
     * 1. Updating product grid using product refinement, sorting and paging
     * 2. Load previous/next product tile
     * 3. Allow handling no search result case
     * 4. Allow handling "Enter" keydown event to be able to skip refinements and set focus to the first element of the grid
     * @property {string} data-show-url Search show url
     * @property {string} data-show-ajax-url Search show AJAX url
     * @property {string} data-load-more-block Block with a button `Load more` for products
     * @property {string} data-load-more-prev Block with a button `Load previous` for products
     * @property {string} data-product-progress-indicator - reference element with search progress indicator for products search
     * @property {string} data-content-progress-indicator - reference element with search progress indicator for content search
     * @property {string} data-load-more-content-block - Block with a button `Load more` for content
     * @property {object} data-accessibility-alerts - Accessibility alerts messages for different user actions
     * @property {boolean} data-first-page - First page flag
     * Possible values are: `filtersapplied`, `filterremoved`, `sortingapplied`, `productlistupdated`, `addedtowishlist`, `previousstatereverted`
     * @example <caption>Example of ProductListingMgr widget usage</caption>
     * <div
     *     data-widget="productListingMgr"
     *     data-show-url="${pdict.productSearch.historyUrl}"
     *     data-show-ajax-url="${pdict.productSearch.ajaxUrl}"
     *     data-event-keydown="handleKeydown"
     *     data-auto-activation="true"
     *     data-accessibility-alerts='{
     *         "filtersapplied": "${Resource.msg('alert.filtersapplied', 'search', null)}",
     *         "filterremoved": "${Resource.msg('alert.filterremoved', 'search', null)}",
     *         "sortingapplied": "${Resource.msg('alert.sortingapplied', 'search', null)}",
     *         "productlistupdated": "${Resource.msg('alert.productlistupdated', 'search', null)}",
     *         "contentlistupdated": "${Resource.msg('alert.contentlistupdated', 'search', null)}",
     *         "addedtowishlist": "${Resource.msg('alert.addedtowishlist', 'product', null)}",
     *         "previousstatereverted": "${Resource.msg('alert.previousstatereverted', 'search', null)}"
     *     }'
     * >
     *     .... product list contents
     * </div>
     */
    class ProductListingMgr extends Tabs {
        prefs() {
            return {
                classesActiveSection: 'm-expanded',
                classesBusy: 'm-busy',
                loadMoreBlock: 'loadMoreBlock',
                loadPrevBlock: 'loadPrevBlock',
                loadMoreContentBlock: 'loadMoreContentBlock',
                accessibilityAlerts: <TAccessibilityAlerts>{},
                showAjaxUrl: '',
                showUrl: '',
                firstPage: true,
                ...super.prefs()
            };
        }

        /**
         * @description Initialize widget logic
         * @returns {void}
         */
        init() {
            super.init();
            this.initEvents();

            // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
            this.handleHistory(false);

            if (!this.prefs().firstPage) {
                scrollWindowTo(this.ref('grid').get(), true);
            }
        }

        /**
         * @description Update product grid by url
         * @param {string} url Update URL
         * @param {RefElement} [ref] Reference element
         * @param {boolean} [handleHistory] Handle history flag
         * @emits "products.grid.updated"
         * @returns {Promise<object|null>} Promise object represents server response with products list markup
         */
        updateByUrl(url, ref, handleHistory) {
            this.busy();

            return getContentByUrl(url)
                .then(response => {
                    if (typeof response === 'string') {
                        return this.render(undefined, undefined, ref || this.ref('self'), response)
                            .then(() => {
                                this.eventBus().emit(GRID_UPDATING_EVENT);

                                if (handleHistory) {
                                    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 0.
                                    this.handleHistory(handleHistory);
                                    scrollWindowTo(this.ref('grid').get(), true);
                                }
                            })
                            .then(() => response);
                    } else {
                        return response;
                    }
                }).catch(() => {
                    this.handleNoResult();

                    return Promise.resolve(null);
                })
                .finally(() => {
                    this.unbusy();
                });
        }

        /**
         * @description Handle History
         * @param {boolean} [isReplace] Replace flag
         * @param {object} [history] history object
         * @returns {void}
         */
        handleHistory(isReplace = false, history) {
            const state = {
                ajaxUrl: history ? history.showAjaxUrl : this.prefs().showAjaxUrl
            };
            let historyURL = history ? history.showUrl : this.prefs().showUrl;

            if (document.location.hash) {
                historyURL += document.location.hash;
            }

            if (isReplace) {
                window.history.replaceState(state, '', historyURL);
            }
        }

        /**
         * @description Update View
         * @listens dom#click
         * @param {RefElement|widget} button Target element
         * @returns {void}
         */
        updateView(button) {
            const updateURL = button.data('href');
            const refinementSelected = button.data('checked');
            const alertMessage = refinementSelected
                ? this.prefs().accessibilityAlerts.filterremoved
                : this.prefs().accessibilityAlerts.filtersapplied;

            this.updateByUrl(updateURL, undefined, true)
                .then(() => this.accessibilityAlert(alertMessage));
        }

        /**
         * @description Update Grid
         * @listens dom#change
         * @param {InstanceType<InputSelect>} select Target element
         * @returns {void}
         */
        updateGrid(select) {
            const selectedSorting = select.getSelectedOptions();

            if (selectedSorting) {
                const url = selectedSorting.data('url');

                this.updateByUrl(url, undefined, true)
                    .then(() => this.accessibilityAlert(this.prefs().accessibilityAlerts.sortingapplied));
            }
        }

        /**
         * @description Load more products
         * @listens dom#click
         * @param {RefElement} button Target element
         * @returns {void}
         */
        loadMore(button) {
            const href = button.data('show-url');
            const history = {
                showUrl: button.attr('href'),
                showAjaxUrl: button.data('show-ajax-url')
            };
            let url = '';

            if (typeof href === 'string') {
                url = href;
            }

            this.ref('loadMoreBlock').remove();
            this.loadChunk(url, response => {
                if (typeof response === 'string') {
                    this.ref('productGrid').append(response);
                    this.accessibilityAlert(this.prefs().accessibilityAlerts.productlistupdated);
                    setTimeout(() => this.eventBus().emit(GRID_UPDATING_EVENT), 0);
                }
            }, true, history);
        }

        /**
         * @description Load previous products
         * @listens dom#click
         * @param {RefElement} button Target element
         * @returns {void}
         */
        loadPrev(button) {
            const href = button.data('show-url');
            const history = {
                showUrl: button.attr('href'),
                showAjaxUrl: button.data('show-ajax-url')
            };
            let url = '';

            if (typeof href === 'string') {
                url = href;
            }

            this.ref('loadPrevBlock').remove();
            this.loadChunk(url, response => {
                if (typeof response === 'string') {
                    this.ref('productGrid').prepend(response);
                    this.accessibilityAlert(this.prefs().accessibilityAlerts.productlistupdated);
                    setTimeout(() => this.eventBus().emit(GRID_UPDATING_EVENT), 0);
                }
            }, true, history);
        }

        /**
         * @description Load more content
         * @listens dom#click
         * @param {RefElement} button Target element
         * @returns {void}
         */
        loadMoreContent(button) {
            const href = button.attr('href');
            let url = '';

            if (typeof href === 'string') {
                url = href;
            }

            this.ref('loadMoreContentBlock').remove();

            // @ts-expect-error ts-migrate(2554) FIXME: Expected 4 arguments, but got 3.
            this.loadChunk(url, response => {
                if (typeof response === 'string') {
                    this.ref('contentGrid').append(response);
                    // eslint-disable-next-line spellcheck/spell-checker
                    this.accessibilityAlert(this.prefs().accessibilityAlerts.contentlistupdated);
                }
            }, false);
        }

        /**
         * @description Loads chunk of data and executes callback. Data might be either products or content
         * @param {string} url load chunk url
         * @param {(arg: Response) => void} successCb success callback to be called after chunk was loaded with response as an argument
         * @param {boolean} handleHistory do we need to handle history change after chunk loaded
         * @param {object} [history] history object
         */
        loadChunk(url, successCb, handleHistory, history) {
            if (!url) {
                // eslint-disable-next-line no-console
                console.error('Somithing wrong with target URL');
            } else {
                this.busy();
                getContentByUrl(
                    appendParamToURL(url, 'selectedUrl', url)
                ).then(response => {
                    successCb(response);

                    if (handleHistory) {
                        this.handleHistory(true, history);
                    }
                }).finally(() => {
                    this.unbusy();
                });
            }
        }

        /**
         * @description Show appropriate Global Alert for page changes
         * @emits "alert.show"
         * @param {string} message - alert message
         * @returns {void}
         */
        accessibilityAlert(message) {
            // We need to show alerts with delay to not overlap with other big changes on the page
            /**
             * @description Global event to show alert
             * @event "alert.show"
             */
            setTimeout(() => this.eventBus().emit('alert.show', { accessibilityAlert: message }), 400);
        }

        /**
         * @description Busy
         * @returns {void}
         */
        busy() {
            this.ref('productGrid').addClass(this.prefs().classesBusy).attr('aria-busy', 'true');
            this.ref('contentGrid').addClass(this.prefs().classesBusy).attr('aria-busy', 'true');
        }

        /**
         * @description Unbusy
         * @returns {void}
         */
        unbusy() {
            this.ref('productGrid').removeClass(this.prefs().classesBusy).attr('aria-busy', false);
            this.ref('contentGrid').removeClass(this.prefs().classesBusy).attr('aria-busy', false);
        }

        /**
         * @description Skip refinements and focus first element in product grid on "Enter" keydown event
         * @listens dom#keydown
         * @param {HTMLElement} _ Source of keydown event
         * @param {KeyboardEvent} event  Event object
         * @returns {void}
         */
        skipRefinements(_, event) {
            let preventEventActions = false;
            let focused = false;

            switch (event.keyCode) {
                case keyCode.RETURN:
                    scrollWindowTo(this.ref('productGrid').get(), true);
                    this.getById('firstTile', element => {
                        focused = true;
                        // @ts-expect-error ts-migrate(2339) FIXME: Property 'focus' does not exist on type 'Widget'.
                        element.focus();
                    });

                    if (!focused && this.items) {
                        for (let i = 0; i < this.items.length; i++) {
                            const item = this.items[i];

                            // @ts-expect-error check gridItem definition
                            if (item.prefs().gridItem) {
                                // @ts-expect-error ts-migrate(2339) FIXME: Property 'focus' does not exist on type 'Widget'.
                                item.focus();
                                break;
                            }
                        }
                    }

                    preventEventActions = true;
                    break;

                default:
                    break;
            }

            if (preventEventActions) {
                event.preventDefault();
                event.stopPropagation();
            }
        }

        /**
         * @description Init Events
         * @emits "alert.show"
         * @returns {void}
         */
        initEvents() {
            this.ev('popstate', (_target, event) => {
                if (event instanceof PopStateEvent && event.state) {
                    const hashChangedOnly = get(event, 'state.hashChangedOnly', false);

                    if (hashChangedOnly) {
                        this.handleUrlChange(false);

                        return;
                    }

                    let updateUrl = get(event, 'state.ajaxUrl', window.location.href);

                    updateUrl = appendParamToURL(updateUrl, 'history', 'true');

                    this.updateByUrl(updateUrl, undefined, false)
                        .then(() => {
                            this.handleUrlChange(false);
                            // eslint-disable-next-line spellcheck/spell-checker
                            const accessibilityAlert = this.prefs().accessibilityAlerts.previousstatereverted;

                            /**
                             * @description Global event to show alert
                             * @event "alert.show"
                             */
                            this.eventBus().emit('alert.show', {
                                accessibilityAlert
                            });
                        });
                }
            }, window);
        }

        /**
         * @description No Result Combination handler
         * @returns {void}
         */
        handleNoResult() {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'showModal' does not exist on type 'Widget... Remove this comment to see the full error message
            this.getById('noResultPopup', popup => popup.showModal());
        }
    }

    return ProductListingMgr;
}
