import { scrollWindowTo } from 'widgets/toolbox/scroll';
import { getContentByUrl } from 'widgets/toolbox/ajax';
const GRID_UPDATING_EVENT = 'products.grid.updated';

type TProductListingMgr = ReturnType<typeof import('widgets/search/ProductListingMgr').default>;
import { IRefElement } from 'widgets/toolbox/RefElement';

/**
 * @param ProductListingMgr Base widget for extending
 * @returns ProductListingMgrExt widget
 */
export default function (ProductListingMgr: TProductListingMgr) {
    /**
     * @category widgets
     * @subcategory search
     * @class ProductListingMgr
     * @augments ProductListingMgr
     * @classdesc Represents ProductListingMgr component with next features:
     */
    class ProductListingMgrExt extends ProductListingMgr {
        /**
         * @description Update product grid by url
         * @param url Update URL
         * @param ref Reference element
         * @param handleHistory Handle history flag
         * @emits "products.grid.updated"
         * @returns Promise object represents server response with products list markup
         */
        updateByUrl(url: string, ref?: IRefElement, handleHistory?: boolean): Promise<Record<string, unknown>|null> {
            this.busy();

            return getContentByUrl(url)
                .then(response => {
                    if (typeof response === 'string') {
                        return this.render(undefined, undefined, ref || this.ref('self'), response)
                            .then(() => {
                                this.eventBus().emit(GRID_UPDATING_EVENT);

                                if (handleHistory) {
                                    // @ts-ignore FIXME in core TS2554: Expected 2 arguments, but got 0.
                                    this.handleHistory(handleHistory);
                                    scrollWindowTo(this.ref('grid').get(), true);
                                }
                            })
                            .then(() => response);
                    } else {
                        return response;
                    }
                }).catch(() => {
                    if (!/\?q=/g.test(url)) {
                        // By this we filter refinements requests and search requests.
                        // Search request fired when page returned from Back-Forward Cache.
                        // We should show "No refinement combination" dialog only in case of refinement requests.
                        this.handleNoResult();
                    }

                    return Promise.resolve(null);
                })
                .finally(() => {
                    this.unbusy();
                });
        }
    }

    return ProductListingMgrExt;
}
